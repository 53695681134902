import React from 'react'
import styled from 'styled-components'
import { Box } from 'pcln-design-system'
import {
  ChatBotPopOver,
  StickyChatBotOpener,
  useSuppressOnNativeWebView
} from '@pcln/penny-chat-bot'
import type { GA4ProductType, GA4PageNameType } from '@/ga4types'
import { type ChatBotPopOverVal } from './TravelChatBot'
import { firePennyTooltipDisplayEvent, firePennyTooltipClickEvent } from './ga4'

const ChatNotificationWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 7;
`

function ChatNotification({
  openChatBotPopOver,
  setOpenChatBotPopOver,
  setChatBotDialogOpeningState,
  isChatBotDialogOpened,
  pageName,
  productType
}: {
  openChatBotPopOver: ChatBotPopOverVal
  isChatBotDialogOpened: boolean
  setChatBotDialogOpeningState: (value: React.SetStateAction<boolean>) => void
  setOpenChatBotPopOver: (
    value: React.SetStateAction<ChatBotPopOverVal>
  ) => void
  pageName: GA4PageNameType
  productType?: GA4ProductType
}) {
  const { shouldSuppress } = useSuppressOnNativeWebView()

  if (shouldSuppress) {
    return null
  }
  return (
    <ChatNotificationWrapper>
      {openChatBotPopOver === 'OPEN' ? (
        <ChatBotPopOver
          onClose={() => {
            setOpenChatBotPopOver('ALREADY_OPENED')
          }}
          onOpen={() => {
            firePennyTooltipClickEvent(pageName, productType)
            setChatBotDialogOpeningState(true)
            setOpenChatBotPopOver('ALREADY_OPENED')
          }}
          onDisplay={() => {
            firePennyTooltipDisplayEvent(pageName, productType)
          }}
          animationType="BottomToTopLeft"
          source="homepage"
          isFrequentNotification
          animationProperties={{
            from: {
              x: '100px',
              y: '150px'
            },
            to: {
              x: '0',
              y: '0'
            },
            bottom: '0'
          }}
          text={`Hi, I'm Penny, your AI travel assistant! How can I help you today?`}
          positionProps={{
            bottom: '170px',
            zIndex: '7',
            right: '21px'
          }}
        />
      ) : null}
      <StickyChatBotOpener
        openerTooltipProps={{
          center: false,
          right: true
        }}
        openerPosition={{
          bottom: '20px',
          right: '10px',
          zIndex: '7'
        }}
        openerTooltipStyle={{
          right: '6px'
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onComponentRendered={/* istanbul ignore next */ () => {}}
        isChatBoxOpened={isChatBotDialogOpened}
        onClickHandler={() => {
          setChatBotDialogOpeningState(true)
          setOpenChatBotPopOver('ALREADY_OPENED')
        }}
      />
    </ChatNotificationWrapper>
  )
}

export default ChatNotification
