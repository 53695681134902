import React, { useState, useEffect } from 'react'
import {
  TheChatBot,
  type HandleMarkerResponse,
  SignInMarkerComponent,
  type Locale as ChatBotLocale
} from '@pcln/penny-chat-bot'
import useBootstrapData from '@/hooks/useBootstrapData'
import analytics from '@/shared-utils/analytics'
import type { GA4ProductType, GA4PageNameType } from '@/ga4types'
import type { Locale } from '@/types'
import getCityListComponent from './getCityListComponent'
import { firePennyIconDisplayEvent, firePennyModalOpenedEvent } from './ga4'

const pennyMarkers = {
  JSONOBJECT_CITIES: 'JSONOBJECT_CITIES',
  PROMPT_SIGN_IN: 'PROMPT_SIGN_IN'
} as const

const { JSONOBJECT_CITIES, PROMPT_SIGN_IN } = pennyMarkers

const transformLocale = (bootstrapLocale: Locale): ChatBotLocale => ({
  ...bootstrapLocale,
  dateFormat: bootstrapLocale.dateFormat ?? undefined,
  distanceFormat: bootstrapLocale.distanceFormat ?? undefined
})

export const handleMarkerResponse: (
  signedIn: boolean
) => HandleMarkerResponse = signedIn => (err, marker, fullText) => {
  try {
    if (err) {
      throw err
    } else if (marker === JSONOBJECT_CITIES) {
      return {
        role: 'assistant',
        content: '',
        promptContent: 'Displayed city list to the user.',
        markerComponentProps: { prefixString: fullText }
      }
    } else if (marker === PROMPT_SIGN_IN) {
      return {
        role: 'assistant',
        content: '',
        promptContent: 'Displayed signin marker component.',
        markerComponentProps: { signedIn }
      }
    }
    return {
      role: '',
      content: ''
    }
  } catch (error) {
    const catchErrorMessage = JSON.stringify(error)

    analytics.logError({
      message: `ChatBot - handle marker response error: ${catchErrorMessage}`
    })

    return {
      role: 'assistant',
      content: 'Network error, please retry.'
    }
  }
}

function TravelChatBotModal(props: {
  isChatBotDialogOpened: boolean
  initialPrompt: string
  onModalClose: () => void
  pageName: GA4PageNameType
  productType?: GA4ProductType
}) {
  const [discoveryCitySelected, setDiscoveryCitySelected] = useState('')
  const {
    isChatBotDialogOpened,
    onModalClose,
    initialPrompt,
    pageName,
    productType
  } = props
  const [askPromptClosed, setAskPromptClose] = useState(false)
  const {
    webstats,
    customer: { firstName },
    locale: bootstrapLocale,
    signInStatus: { signedIn },
    moduleInfo
  } = useBootstrapData()
  const componentVersion = moduleInfo?.['@pcln/penny-chat-bot']

  useEffect(() => {
    firePennyIconDisplayEvent(pageName, productType)
  }, [pageName, productType])

  useEffect(() => {
    if (isChatBotDialogOpened) {
      firePennyModalOpenedEvent(pageName, productType)
    }
  }, [isChatBotDialogOpened, pageName, productType])

  const setDiscoveryCity = (city: string) => {
    setDiscoveryCitySelected(city)
  }
  const markerComponents = {
    [JSONOBJECT_CITIES]: getCityListComponent(setDiscoveryCity),
    [PROMPT_SIGN_IN]: SignInMarkerComponent
  }
  const markerResponseHandler = handleMarkerResponse(signedIn)
  const pushPrompt = askPromptClosed ? undefined : initialPrompt

  const locale = transformLocale(bootstrapLocale)

  return (
    <TheChatBot
      viewType={'LANDING' as const}
      componentVersion={componentVersion}
      customerName={firstName ?? ''}
      discoveryCitySelected={discoveryCitySelected}
      handleMarkerResponse={markerResponseHandler}
      markerComponents={markerComponents}
      isChatBoxOpened={isChatBotDialogOpened}
      locale={locale}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChatDialogRendered={() => {}} // not needed right now, the analytics event is fired elsewhere on open
      onClose={() => {
        setAskPromptClose(true)
        onModalClose()
      }}
      startingChatMessagesPayload={{ pushPrompt }}
      webstats={webstats}
    />
  )
}

TravelChatBotModal.displayName = 'TravelChatBotModal'

export default TravelChatBotModal
